import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"

interface AboutProps {
  data: {
    allNotes: {
      edges: {
        node: {
          id: string;
          fields: {
            slug: string;
          }
          frontmatter: {
            title: string;
            Tags: string[];
            categories: string[];
            date: string;
          }
          timeToRead: string;
        }
      }[]
    }
  }
}

export default ({ data }: AboutProps) => {
  const htmlData: any[] = []
  // htmlData.push(
  //   <div>
  //     <p>My Notes</p>
  //     <Link to="/">Home</Link>
  //     <Link to="/notes">Notes</Link>
  //   </div>
  // )

  data.allNotes.edges.forEach(({ node }) => {
    htmlData.push(
      <div key={node.id}>
        <Link to={node.fields.slug}>
          {" "}
          {node.fields.slug} {node.frontmatter.title}
        </Link>
      </div>
    )
  })
  return <Layout>{htmlData}</Layout>
}

export const query = graphql`
  {
    allNotes: allMarkdownRemark {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            Tags
            categories
            date
          }
          timeToRead
        }
      }
    }
  }
`
